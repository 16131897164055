{
    "features": [
        {
            "geometry": {
                "coordinates": [
                    15.676221,
                    48.472193
                ],
                "type": "Point"
            },
            "id": 443006286,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "1",
                "addr:postcode": "3550",
                "addr:street": "Holzplatz",
                "amenity": "pharmacy",
                "dispensing": "yes",
                "key": "amenity",
                "name": "Adler Apotheke Langenlois",
                "opening_hours": "Mo-Fr 08:00-18:00; Sa 08:00-12:30",
                "value": "pharmacy"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.693698,
                    48.490421
                ],
                "type": "Point"
            },
            "id": 513111068,
            "properties": {
                "addr:city": "Zöbing",
                "addr:housenumber": "15",
                "addr:postcode": "3561",
                "addr:street": "Marktplatz",
                "amenity": "restaurant",
                "cuisine": "regional",
                "key": "cuisine",
                "name": "Heiligensteinerhof",
                "phone": "+43 2734 27 69-0",
                "value": "regional",
                "website": "https://www.zoebing.at/heiligensteinerhof/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.698569,
                    48.492116
                ],
                "type": "Point"
            },
            "id": 513111070,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "32",
                "addr:postcode": "3561",
                "addr:street": "Heiligensteinstraße",
                "amenity": "restaurant",
                "at_bev:addr_date": "2020-10-01",
                "cuisine": "regional",
                "key": "cuisine",
                "name": "Gutmann",
                "opening_hours": "We-Sa 11:30-14:30, 18:00-20:00; PH, Su 09:00-18:00",
                "phone": "+43 2734 2334",
                "value": "regional",
                "website": "https://www.gasthaus-gutmann.com/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.687143,
                    48.471392
                ],
                "type": "Point"
            },
            "id": 587730901,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "2",
                "addr:postcode": "3550",
                "addr:street": "Hollerweg",
                "amenity": "doctors",
                "key": "amenity",
                "name": "Dr. Larissa Trybus",
                "opening_hours": "Mo,We,Th,Fr 08:00-12:30; Mo 17:00-19:00",
                "value": "doctors"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.676022,
                    48.473058
                ],
                "type": "Point"
            },
            "id": 598253760,
            "properties": {
                "amenity": "post_box",
                "check_date:collection_times": "2022-11-16",
                "collection_times": "Mo-Fr 17:00",
                "key": "amenity",
                "operator": "Österreichische Post AG",
                "value": "post_box"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.692158,
                    48.4712
                ],
                "type": "Point"
            },
            "id": 996851035,
            "properties": {
                "amenity": "fuel",
                "brand": "Genol",
                "compressed_air": "no",
                "fuel:diesel": "yes",
                "key": "amenity",
                "opening_hours": "24/7",
                "shop": "no",
                "value": "fuel"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.697712,
                    48.516827
                ],
                "type": "Point"
            },
            "id": 1338191558,
            "properties": {
                "designation": "Stoamandlpark",
                "key": "leisure",
                "leisure": "playground",
                "name": "Stoamandlpark",
                "value": "playground"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.691543,
                    48.517869
                ],
                "type": "Point"
            },
            "id": 1338226502,
            "properties": {
                "amenity": "restaurant",
                "cuisine": "regional",
                "key": "cuisine",
                "name": "Weingärtnerei Aichinger",
                "value": "regional"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.695411,
                    48.514297
                ],
                "type": "Point"
            },
            "id": 1338230916,
            "properties": {
                "amenity": "fuel",
                "compressed_air": "yes",
                "fuel:diesel": "yes",
                "fuel:octane_95": "yes",
                "key": "amenity",
                "name": "Esso Lindermayer",
                "value": "fuel"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.575601,
                    48.464326
                ],
                "type": "Point"
            },
            "id": 1365272354,
            "properties": {
                "addr:city": "Droß",
                "addr:country": "AT",
                "addr:housenumber": "53",
                "addr:postcode": "3552",
                "addr:street": "Am Platzl",
                "key": "shop",
                "name": "Bäckerei Kafesy",
                "phone": "+43 02719 301 53",
                "shop": "bakery",
                "value": "bakery",
                "website": "https://www.kafesy.at/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.676892,
                    48.473794
                ],
                "type": "Point"
            },
            "id": 1829944769,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "5",
                "addr:postcode": "3550",
                "addr:street": "Kornplatz",
                "amenity": "cafe",
                "key": "amenity",
                "name": "Café & Wein",
                "opening_hours": "Tu-Sa 09:00-23:00; PH,Su,Mo 09:00-18:00",
                "value": "cafe",
                "website": "https://www.ursinhaus.at/cafeweinbar.html"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.666929,
                    48.474149
                ],
                "type": "Point"
            },
            "id": 2078458868,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "11",
                "addr:postcode": "3550",
                "addr:street": "Zwettler Straße",
                "fixme": "position",
                "key": "tourism",
                "name": "Privatmuseum Steininger",
                "tourism": "museum",
                "value": "museum"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.688863,
                    48.474465
                ],
                "type": "Point"
            },
            "id": 2260247237,
            "properties": {
                "amenity": "post_box",
                "check_date": "2020-12-26",
                "check_date:collection_times": "2023-01-30",
                "collection_times": "Mo-Fr 09:00",
                "key": "amenity",
                "value": "post_box"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.698583,
                    48.471577
                ],
                "type": "Point"
            },
            "id": 2260257100,
            "properties": {
                "amenity": "post_box",
                "key": "amenity",
                "value": "post_box"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.576698,
                    48.462512
                ],
                "type": "Point"
            },
            "id": 3749218955,
            "properties": {
                "addr:city": "Droß",
                "addr:country": "AT",
                "addr:housenumber": "81",
                "addr:postcode": "3552",
                "addr:street": "Hauptstraße",
                "amenity": "restaurant",
                "cuisine": "regional",
                "delivery": "no",
                "key": "cuisine",
                "name": "Zum alten Bierkeller",
                "opening_hours": "We off",
                "outdoor_seating": "yes",
                "phone": "+43 2719 8296",
                "smoking": "isolated",
                "value": "regional"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.574181,
                    48.464677
                ],
                "type": "Point"
            },
            "id": 3749386051,
            "properties": {
                "addr:city": "Droß",
                "addr:country": "AT",
                "addr:housenumber": "27",
                "addr:postcode": "3552",
                "addr:street": "Schloßstraße",
                "amenity": "doctors",
                "email": "daniela@grulich.at",
                "healthcare:speciality": "general",
                "healthcare:speciality:de": "Arzt für Allgemeinmedizin",
                "key": "amenity",
                "name": "Dr. Daniela Grulich",
                "phone": "+43 650 5310925",
                "value": "doctors",
                "website": "http://www.daniela.grulich.at"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.574111,
                    48.464575
                ],
                "type": "Point"
            },
            "id": 3749386065,
            "properties": {
                "addr:city": "Droß",
                "addr:country": "AT",
                "addr:housenumber": "27",
                "addr:postcode": "3552",
                "addr:street": "Schloßstraße",
                "amenity": "doctors",
                "healthcare:speciality": "general",
                "healthcare:speciality:de": "Arzt für Allgemeinmedizin",
                "key": "amenity",
                "name": "Dr. Ursula Ernst",
                "phone": "+43 664 420 56 00",
                "value": "doctors"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.572658,
                    48.469425
                ],
                "type": "Point"
            },
            "id": 3749433297,
            "properties": {
                "addr:city": "Droß",
                "addr:country": "AT",
                "addr:housenumber": "44",
                "addr:postcode": "3552",
                "addr:street": "Schäferhof",
                "amenity": "doctors",
                "contact:mobile": "+43664 281 92 71",
                "contact:phone": "+432719 94121",
                "email": "h-winkler@aon.at",
                "healthcare:speciality": "orthopaedics",
                "healthcare:speciality:de": "Facharzt für Orthopädie und Orthopädische Chirurgie",
                "key": "amenity",
                "name": "Dr. Heinz Winkler - Orthopaedie Schaeferhof",
                "value": "doctors",
                "website": "http://www.osteomyelitis.at"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.630745,
                    48.510882
                ],
                "type": "Point"
            },
            "id": 3773269410,
            "properties": {
                "key": "tourism",
                "name": "Arena",
                "tourism": "attraction",
                "value": "attraction"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.630012,
                    48.509959
                ],
                "type": "Point"
            },
            "id": 3773269411,
            "properties": {
                "key": "tourism",
                "name": "Kräuterspirale",
                "toilets:wheelchair": "no",
                "tourism": "attraction",
                "value": "attraction",
                "wheelchair": "yes"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.629783,
                    48.510332
                ],
                "type": "Point"
            },
            "id": 3773269412,
            "properties": {
                "key": "tourism",
                "name": "Mensch ärgere dich nicht",
                "tourism": "attraction",
                "value": "attraction"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.629593,
                    48.510439
                ],
                "type": "Point"
            },
            "id": 3773270340,
            "properties": {
                "key": "tourism",
                "name": "Strohpyramide",
                "tourism": "attraction",
                "value": "attraction"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.678505,
                    48.555879
                ],
                "type": "Point"
            },
            "id": 3920512200,
            "properties": {
                "addr:city": "Plank",
                "addr:country": "AT",
                "addr:housenumber": "38",
                "addr:postcode": "3564",
                "addr:street": "Kamptalstraße",
                "amenity": "doctors",
                "key": "amenity",
                "name": "Ordination Plank",
                "opening_hours": "Tu 16:00-18:00; Th 08:00-12:00",
                "value": "doctors"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.696158,
                    48.51993
                ],
                "type": "Point"
            },
            "id": 3928892203,
            "properties": {
                "addr:city": "Schönberg am Kamp",
                "addr:country": "AT",
                "addr:housenumber": "12",
                "addr:postcode": "3562",
                "addr:street": "Badgasse",
                "amenity": "doctors",
                "healthcare": "doctor",
                "healthcare:speciality": "emergency;anaesthetics;general_practice",
                "key": "amenity",
                "name": "Dr. Tschiesche",
                "note": "Arzt mit Hausapotheke",
                "opening_hours": "Mo,Tu,Fr 08:00-12:00; Th 16:00-18:00",
                "pharmacy": "yes",
                "source": "survey;local knowledge",
                "value": "doctors",
                "website": "http://www.kamptaldoktor.at/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.689934,
                    48.528607
                ],
                "type": "Point"
            },
            "id": 3983597739,
            "properties": {
                "image": "http://www.smartstyria.at/wp-content/gallery/mondrohr/179793_623314011013664_923427003_n.jpg",
                "key": "tourism",
                "lit": "yes",
                "name": "Mondrohr",
                "tourism": "attraction",
                "value": "attraction"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.681958,
                    48.474342
                ],
                "type": "Point"
            },
            "id": 4068249810,
            "properties": {
                "amenity": "post_office",
                "brand": "DPD Paketshop",
                "brand:wikidata": "Q541030",
                "brand:wikipedia": "en:DPDgroup",
                "key": "amenity",
                "name": "DPD Paketshop",
                "opening_hours": "Mo-Fr 09:00-13:00,14:00-18:00",
                "operator": "Silverweb",
                "value": "post_office"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.69746,
                    48.517461
                ],
                "type": "Point"
            },
            "id": 4282512735,
            "properties": {
                "amenity": "post_box",
                "collection_times": "Mo-Fr 9:30",
                "key": "amenity",
                "operator": "Österreichische Post AG",
                "ref": "3550-9060",
                "value": "post_box"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.617022,
                    48.518865
                ],
                "type": "Point"
            },
            "id": 4293410292,
            "properties": {
                "amenity": "atm",
                "key": "amenity",
                "value": "atm"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.617967,
                    48.520464
                ],
                "type": "Point"
            },
            "id": 4293476390,
            "properties": {
                "key": "tourism",
                "name": "Arche Noah Streuobstwiese",
                "tourism": "attraction",
                "value": "attraction"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.617747,
                    48.519043
                ],
                "type": "Point"
            },
            "id": 4293481989,
            "properties": {
                "addr:housenumber": "40",
                "addr:postcode": "3553",
                "addr:street": "Obere Straße",
                "description": "Jungpflanzen, Saatgut seltener Gemüse- und Nutzpflanzen sowie Gartengeräte, Bücher und Geschenke",
                "key": "tourism",
                "name": "Arche Noah Shop",
                "opening_hours": "Tu-Fr 10:00-22:00; Sa-Su 10:00-17:00",
                "tourism": "attraction",
                "value": "attraction",
                "website": "https://www.arche-noah.at/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.616937,
                    48.518825
                ],
                "type": "Point"
            },
            "id": 4396474432,
            "properties": {
                "amenity": "cafe",
                "key": "amenity",
                "name": "Schloss Cafe",
                "value": "cafe"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.602397,
                    48.472507
                ],
                "type": "Point"
            },
            "id": 4839699508,
            "properties": {
                "amenity": "cafe",
                "key": "amenity",
                "name": "Velino",
                "value": "cafe"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.632929,
                    48.51232
                ],
                "type": "Point"
            },
            "id": 5046159249,
            "properties": {
                "addr:city": "Schiltern",
                "addr:postcode": "3553",
                "addr:street": "Laabergstraße",
                "alt_name": "Brau Schneider",
                "craft": "brewery",
                "fixme": "building and more details",
                "key": "craft",
                "name": "BrauSchneider",
                "opening_hours": "Mo-Fr 09:00-17:00",
                "operator": "Schneider",
                "phone": "+43 2734 32917",
                "product": "beer",
                "value": "brewery",
                "website": "https://www.brauschneider.at/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.616776,
                    48.518864
                ],
                "type": "Point"
            },
            "id": 5046168925,
            "properties": {
                "addr:city": "Schiltern",
                "addr:country": "AT",
                "addr:housenumber": "45",
                "addr:postcode": "3553",
                "addr:street": "Obere Straße",
                "fee": "yes",
                "key": "tourism",
                "name": "Modellbahnwelt-Schiltern",
                "opening_hours": "We-Mo 10:00-18:00; Tu off",
                "tourism": "attraction",
                "value": "attraction",
                "website": "https://www.modellbahnwelt-schiltern.at/",
                "wheelchair": "no"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.674411,
                    48.472395
                ],
                "type": "Point"
            },
            "id": 5078603597,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "1",
                "addr:postcode": "3550",
                "addr:street": "Kaserngasse",
                "amenity": "restaurant",
                "at_bev:addr_date": "2017-04-07",
                "cuisine": "regional",
                "key": "cuisine",
                "name": "Liubisa",
                "opening_hours": "Tu-Th 16:00-01:00, Fr,Sa 16:00-02:00",
                "phone": "+43 650 9991893",
                "smoking": "outside",
                "source": "geoimage.at maxres",
                "value": "regional",
                "website": "https://www.facebook.com/LiubisaLangenlois/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.693291,
                    48.51463
                ],
                "type": "Point"
            },
            "id": 5085350674,
            "properties": {
                "addr:city": "Schönberg",
                "addr:country": "AT",
                "addr:housenumber": "9",
                "addr:postcode": "3562",
                "addr:street": "Mollandserstraße",
                "email": "gaertner@straussenland.at",
                "key": "tourism",
                "name": "Straußenland",
                "opening_hours": "Jan-Feb: Mo-Sa 08:00-12:00; Mar: Mo-Fr 08:00-12:00,14:00-17:00; Mar: Sa 08:00-12:00; Apr-Oct: Mo-Sa 08:00-12:00,14:00-17:00; Apr-Oct: Su,PH 14:00-17:00; Nov-Dec: Mo-Fr 08:00-12:00,14:00-17:00; Nov-Dec: Sa 08:00-12:00",
                "phone": "+4327338224",
                "shop": "farm",
                "tourism": "attraction",
                "value": "attraction",
                "website": "https://www.straussenland.at/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.630069,
                    48.510428
                ],
                "type": "Point"
            },
            "id": 5178024509,
            "properties": {
                "key": "tourism",
                "name": "Schach",
                "tourism": "attraction",
                "value": "attraction"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.630864,
                    48.509785
                ],
                "type": "Point"
            },
            "id": 5178115048,
            "properties": {
                "key": "tourism",
                "name": "Labyrinth",
                "tourism": "attraction",
                "value": "attraction"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.575068,
                    48.463906
                ],
                "type": "Point"
            },
            "id": 5554513758,
            "properties": {
                "addr:city": "Droß",
                "addr:housenumber": "73",
                "addr:postcode": "3552",
                "addr:street": "Am Platzl",
                "alt_name": "Winzerhof am Platzl",
                "amenity": "restaurant",
                "cuisine": "heuriger",
                "email": "buschenschank_nigl@aon.at",
                "key": "cuisine",
                "name": "Heuriger Nigl Ingeborg",
                "outdoor_seating": "yes",
                "phone": "+43 2719 8524",
                "shop": "wine",
                "value": "heuriger",
                "website": "http://wein-nigl.at"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.571098,
                    48.466279
                ],
                "type": "Point"
            },
            "id": 5554693157,
            "properties": {
                "addr:city": "Droß",
                "addr:country": "AT",
                "addr:housenumber": "291",
                "addr:postcode": "3552",
                "addr:street": "Siedlungsgasse",
                "amenity": "doctors",
                "healthcare": "doctor",
                "healthcare:speciality": "gynaecology",
                "key": "amenity",
                "name": "Dr. Ewald Aigner",
                "value": "doctors"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.650517,
                    48.479069
                ],
                "type": "Point"
            },
            "id": 5864973672,
            "properties": {
                "building": "yes",
                "building:condition": "completely_ruinous",
                "castle_type": "defensive",
                "castle_type:de": "burgruine",
                "fixme": "no wikidata object",
                "historic": "castle",
                "historic:civilization": "medieval",
                "key": "historic",
                "name": "Ruine Taubenfang",
                "ruins": "yes",
                "value": "castle"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.670152,
                    48.477973
                ],
                "type": "Point"
            },
            "id": 6253451785,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "54",
                "addr:postcode": "3550",
                "addr:street": "Schilterner Straße",
                "amenity": "restaurant",
                "contact:phone": "+43 664 103 92 62",
                "cuisine": "heuriger",
                "key": "cuisine",
                "name": "Heuriger zur langen Sonne",
                "operator": "Fam. Köstler",
                "outdoor_seating": "yes",
                "phone": "+436641039262   +436644107601",
                "value": "heuriger",
                "website": "https://www.zur-langen-sonne.at/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.682661,
                    48.474404
                ],
                "type": "Point"
            },
            "id": 6610314575,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "36",
                "addr:postcode": "3550",
                "addr:street": "Bahnstraße",
                "amenity": "doctors",
                "healthcare": "doctor",
                "key": "amenity",
                "name": "Dr. Enikö Meszaros",
                "opening_hours": "Mo,Tu,Th,Fr 07:30-12:00; Th 16:30-18:30",
                "value": "doctors"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.695025,
                    48.522097
                ],
                "type": "Point"
            },
            "id": 6934695499,
            "properties": {
                "amenity": "restaurant",
                "cuisine": "regional",
                "key": "cuisine",
                "name": "Badbuffet",
                "value": "regional"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.596001,
                    48.471723
                ],
                "type": "Point"
            },
            "id": 7430682977,
            "properties": {
                "addr:city": "Lengenfeld",
                "addr:country": "AT",
                "addr:housenumber": "17a",
                "addr:postcode": "3552",
                "addr:street": "Langenloiser Straße",
                "amenity": "doctors",
                "healthcare": "doctor",
                "healthcare:speciality": "general",
                "key": "amenity",
                "name": "Dr. Rainer Ludhammer",
                "phone": "+43 2719 78587",
                "value": "doctors",
                "website": "https://praxis-ludhammer.com/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.6604,
                    48.475926
                ],
                "type": "Point"
            },
            "id": 8146215072,
            "properties": {
                "amenity": "post_box",
                "collection_times": "Mo-Fr 09:00",
                "key": "amenity",
                "value": "post_box"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.676178,
                    48.471197
                ],
                "type": "Point"
            },
            "id": 8559845586,
            "properties": {
                "addr:city": "Langenlois",
                "addr:housenumber": "9",
                "addr:postcode": "3550",
                "addr:street": "Kremser Straße",
                "amenity": "doctors",
                "healthcare": "doctor",
                "healthcare:speciality": "dermatology",
                "key": "amenity",
                "name": "Dr. Karin Raimitz Kreyer",
                "opening_hours": "\"nach telefonischer Vereinbarung\"",
                "phone": "+43 2734 32 004",
                "value": "doctors",
                "website": "http://www.raimitz-kreyer.at/index.php"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.606843,
                    48.472791
                ],
                "type": "Point"
            },
            "id": 8757815696,
            "properties": {
                "addr:city": "Lengenfeld",
                "addr:country": "AT",
                "addr:housenumber": "83",
                "addr:postcode": "3552",
                "addr:street": "Langenloiser Straße",
                "amenity": "restaurant",
                "cuisine": "heuriger",
                "key": "cuisine",
                "name": "Heuriger Völkl",
                "opening_hours": "\"Siehe Heurigentermine auf Website\"",
                "outdoor_seating": "yes",
                "phone": "+436769186121",
                "value": "heuriger",
                "website": "https://www.weingut-voelkl.at"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.688569,
                    48.532794
                ],
                "type": "Point"
            },
            "id": 9056578296,
            "properties": {
                "amenity": "restaurant",
                "cuisine": "heuriger",
                "delivery": "no",
                "key": "cuisine",
                "name": "Heuriger Staritzbichler-Deibler",
                "outdoor_seating": "yes",
                "value": "heuriger"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.629558,
                    48.509973
                ],
                "type": "Point"
            },
            "id": 9057098626,
            "properties": {
                "amenity": "cafe",
                "key": "amenity",
                "value": "cafe"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.604869,
                    48.498266
                ],
                "type": "Point"
            },
            "id": 9703621547,
            "properties": {
                "amenity": "post_box",
                "collection_times": "Mo-Fr 11:00",
                "key": "amenity",
                "value": "post_box"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.669805,
                    48.47447
                ],
                "type": "Point"
            },
            "id": 9703750095,
            "properties": {
                "amenity": "post_box",
                "collection_times": "Mo-Fr 09:30",
                "key": "amenity",
                "value": "post_box"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.691246,
                    48.473011
                ],
                "type": "Point"
            },
            "id": 10047411923,
            "properties": {
                "access": "yes",
                "dog": "no",
                "indoor": "no",
                "key": "leisure",
                "leisure": "playground",
                "name": "Europapark",
                "playground:theme": "playground",
                "source": "local knowledge",
                "surface": "grass",
                "value": "playground",
                "wheelchair": "no"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.572888,
                    48.464726
                ],
                "type": "Point"
            },
            "id": 10216555829,
            "properties": {
                "amenity": "atm",
                "currency:EUR": "yes",
                "drive_through": "no",
                "key": "amenity",
                "value": "atm"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.677433,
                    48.473595
                ],
                "type": "Point"
            },
            "id": 10216571877,
            "properties": {
                "amenity": "atm",
                "currency:EUR": "yes",
                "drive_through": "no",
                "key": "amenity",
                "value": "atm"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.676244,
                    48.471738
                ],
                "type": "Point"
            },
            "id": 10219802047,
            "properties": {
                "addr:city": "Langenlois",
                "addr:housenumber": "1",
                "addr:postcode": "3550",
                "addr:street": "Rudolfstraße",
                "amenity": "doctors",
                "healthcare": "doctor",
                "healthcare:speciality": "gynaecology",
                "key": "amenity",
                "name": "OA Dr. Franz Burger",
                "opening_hours": "Tu 15:00-18:00; Fr 09:00-12:00; PH off",
                "operator": "OA Dr. Franz Burger",
                "phone": "+43 2734 3370",
                "value": "doctors"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.658546,
                    48.476506
                ],
                "type": "Point"
            },
            "id": 10836687763,
            "properties": {
                "addr:city": "Langenlois",
                "addr:housenumber": "8",
                "addr:postcode": "3550",
                "addr:street": "Mühlgasse",
                "amenity": "restaurant",
                "contact:email": "steiner@hauermandl.at",
                "cuisine": "heuriger",
                "key": "cuisine",
                "name": "Heuriger",
                "value": "heuriger",
                "website": "https://www.hauermandl.at/heurigenlokal.html"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.600244,
                    48.46945
                ],
                "type": "Point"
            },
            "id": 11300273192,
            "properties": {
                "key": "tourism",
                "name": "Baumpresse",
                "opening_hours": "24/7",
                "tourism": "attraction",
                "value": "attraction"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.595484,
                    48.471519
                ],
                "type": "Point"
            },
            "id": 11329537621,
            "properties": {
                "addr:city": "Lengenfeld",
                "addr:country": "AT",
                "addr:housenumber": "15",
                "addr:postcode": "3552",
                "addr:street": "Langenloiser Straße",
                "amenity": "restaurant",
                "cuisine": "regional",
                "key": "cuisine",
                "mobile": "+43 676 506 55 71",
                "name": "Gasthaus Anderl",
                "value": "regional"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.602006,
                    48.472027
                ],
                "type": "Point"
            },
            "id": 11329537623,
            "properties": {
                "addr:city": "Lengenfeld",
                "addr:country": "AT",
                "addr:housenumber": "67",
                "addr:postcode": "3552",
                "addr:street": "Langenloiser Straße",
                "brand": "Nah & Frisch",
                "brand:wikidata": "Q1963643",
                "brand:wikipedia": "de:Nah & Frisch",
                "key": "shop",
                "name": "Nah & Frisch",
                "operator": "Erich Hufnagl",
                "phone": "+43 2719 2362",
                "shop": "supermarket",
                "value": "supermarket",
                "website": "https://www.nahundfrisch.at/de/kaufmann/hufnagl_erich"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.586207,
                    48.474894
                ],
                "type": "Point"
            },
            "id": 11329560728,
            "properties": {
                "addr:city": "Lengenfeld",
                "addr:country": "AT",
                "addr:housenumber": "63",
                "addr:postcode": "3552",
                "addr:street": "Gföhler Straße",
                "amenity": "restaurant",
                "contact:phone": "+43 676 922 42 05",
                "cuisine": "heuriger",
                "key": "cuisine",
                "name": "Heuriger Loimer",
                "operator": "Weingut Franz Loimer",
                "outdoor_seating": "yes",
                "value": "heuriger",
                "website": "http://www.f-loimer.at/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.616842,
                    48.473813
                ],
                "type": "Point"
            },
            "id": 11397919664,
            "properties": {
                "addr:city": "Lengenfeld",
                "addr:country": "AT",
                "addr:housenumber": "1",
                "addr:postcode": "3552",
                "addr:street": "Am Golfplatz",
                "amenity": "restaurant",
                "cuisine": "regional",
                "key": "cuisine",
                "name": "GASThouse",
                "operator": "Golfclub Lengenfeld",
                "outdoor_seating": "yes",
                "phone": "+43 2719 871 03",
                "value": "regional",
                "website": "http://golflengenfeld.at/restaurant/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.615529,
                    48.504577999999995
                ],
                "type": "Point"
            },
            "id": 29733346,
            "properties": {
                "key": "leisure",
                "leisure": "sea_bath",
                "name": "Badeteich Kronsegg",
                "natural": "water",
                "source": "geoimage.at hires",
                "value": "sea_bath"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.676067765130693,
                    48.47303540289067
                ],
                "type": "Point"
            },
            "id": 29734981,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "9",
                "addr:postcode": "3550",
                "addr:street": "Holzplatz",
                "amenity": "post_office",
                "building": "yes",
                "check_date:opening_hours": "2023-01-02",
                "key": "amenity",
                "name": "Post Filiale 3550",
                "opening_hours": "Mo-Fr 08:00-12:00,14:00-17:30; Sa,Su,PH off",
                "operator": "Österreichische Post AG",
                "value": "post_office",
                "website": "https://www.post.at"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.6766165,
                    48.473033
                ],
                "type": "Point"
            },
            "id": 29734982,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "9",
                "addr:postcode": "3550",
                "addr:street": "Rathausstraße",
                "at_bev:addr_date": "2021-10-01",
                "building": "yes",
                "key": "tourism",
                "name": "Heimatmuseum",
                "source": "plan.at 2009,geoimage.at",
                "tourism": "museum",
                "value": "museum",
                "wikidata": "Q38147930"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.5735025,
                    48.465357
                ],
                "type": "Point"
            },
            "id": 33909691,
            "properties": {
                "key": "leisure",
                "leisure": "playground",
                "value": "playground"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.684780971044798,
                    48.47141157819705
                ],
                "type": "Point"
            },
            "id": 45673139,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "47",
                "addr:postcode": "3550",
                "addr:street": "Wiener Straße",
                "brand": "Hofer",
                "brand:wikidata": "Q15815751",
                "brand:wikipedia": "de:Hofer KG",
                "building": "retail",
                "check_date:opening_hours": "2022-11-30",
                "contact:phone": "+43 57 0303 4125",
                "key": "shop",
                "name": "Hofer",
                "opening_hours": "Mo-Fr 07:30-19:30; Sa 07:30-18:00",
                "shop": "supermarket",
                "source": "geoimage.at",
                "value": "supermarket"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.680635935527702,
                    48.471877825486345
                ],
                "type": "Point"
            },
            "id": 45673241,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "31",
                "addr:postcode": "3550",
                "addr:street": "Wiener Straße",
                "brand": "Spar",
                "brand:wikidata": "Q610492",
                "brand:wikipedia": "en:Spar (retailer)",
                "building": "supermarket",
                "building:levels": "1",
                "contact:phone": "+43 2734 322 60",
                "key": "shop",
                "name": "Spar",
                "opening_hours": "Mo-Fr 07:00-19:15; Sa 07:15-18:00",
                "roof:levels": "0",
                "shop": "supermarket",
                "source": "geoimage.at",
                "value": "supermarket",
                "wheelchair": "yes"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.688002000000001,
                    48.470387
                ],
                "type": "Point"
            },
            "id": 46105382,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "44a",
                "addr:postcode": "3550",
                "addr:street": "Wiener Straße",
                "at_bev:addr_date": "2021-10-01",
                "brand": "Lidl",
                "brand:wikidata": "Q151954",
                "brand:wikipedia": "en:Lidl",
                "building": "retail",
                "key": "shop",
                "name": "Lidl",
                "opening_hours": "Mo-Fr 07:40-20:00; Sa 07:40-18:00",
                "shop": "supermarket",
                "value": "supermarket"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.68585584195901,
                    48.47116416406498
                ],
                "type": "Point"
            },
            "id": 47426890,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "2",
                "addr:postcode": "3550",
                "addr:street": "Hartriegelstraße",
                "amenity": "pharmacy",
                "building": "yes",
                "check_date:opening_hours": "2023-07-04",
                "contact:phone": "+43 2734 227 22",
                "dispensing": "yes",
                "healthcare": "pharmacy",
                "key": "amenity",
                "name": "Kamptal Apotheke",
                "opening_hours": "Mo-Fr 08:00-18:00; Sa 08:00-12:30",
                "source": "survey",
                "value": "pharmacy",
                "website": "https://kamptal-apotheke.at/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.693863752794309,
                    48.47512060534967
                ],
                "type": "Point"
            },
            "id": 54051144,
            "properties": {
                "addr:city": "Langenlois",
                "addr:housenumber": "51",
                "addr:postcode": "3550",
                "addr:street": "Wiener Straße",
                "brand": "Penny",
                "brand:wikidata": "Q284688",
                "brand:wikipedia": "en:Penny (supermarket)",
                "building": "yes",
                "key": "shop",
                "name": "Penny",
                "shop": "supermarket",
                "source": "Bing",
                "value": "supermarket",
                "wheelchair": "yes"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.685932548024585,
                    48.47386469685656
                ],
                "type": "Point"
            },
            "id": 71949426,
            "properties": {
                "access": "yes",
                "key": "leisure",
                "leisure": "playground",
                "source": "Bing",
                "surface": "grass",
                "value": "playground"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.699485999999995,
                    48.471541
                ],
                "type": "Point"
            },
            "id": 77354135,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "21",
                "addr:postcode": "3550",
                "addr:street": "Krumpöck-Allee",
                "at_bev:addr_date": "2021-10-01",
                "building": "yes",
                "castle_type": "stately",
                "castle_type:de": "schloss",
                "description": "Seminarhotel, Lehrbauhof, Bauakademie",
                "heritage": "2",
                "heritage:operator": "bda",
                "historic": "castle",
                "key": "historic",
                "name": "Schloss Haindorf",
                "ref:at:bda": "31920",
                "value": "castle",
                "website": "https://www.haindorf.at/",
                "wikidata": "Q2241401",
                "wikimedia_commons": "Category:Schloss Haindorf",
                "wikipedia": "de:Schloss Haindorf"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.676049637392149,
                    48.47586647980271
                ],
                "type": "Point"
            },
            "id": 83603991,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "1",
                "addr:postcode": "3550",
                "addr:street": "Loisium-Allee",
                "at_bev:addr_date": "2021-04-01",
                "building": "yes",
                "key": "tourism",
                "name": "Loisium Weinwelt",
                "opening_hours": "Dec-Mar We-Su 10:00-17:30; Apr-Nov Mo-Su 10:00-17:30",
                "operator": "Loisium Kellerwelt Betriebs GmbH & Co KG",
                "source": "Bing",
                "tourism": "attraction",
                "value": "attraction",
                "wheelchair": "no",
                "wikidata": "Q86960240"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.695601654586381,
                    48.474778130696
                ],
                "type": "Point"
            },
            "id": 83603992,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "46",
                "addr:postcode": "3550",
                "addr:street": "Wiener Straße",
                "at_bev:addr_date": "2021-10-01",
                "brand": "Billa",
                "brand:wikidata": "Q537781",
                "brand:wikipedia": "en:Billa (supermarket)",
                "building": "yes",
                "key": "shop",
                "name": "Billa",
                "opening_hours": "Mo-Fr 07:30-19:50; Sa 07:40-18:00",
                "shop": "supermarket",
                "source": "Bing",
                "value": "supermarket",
                "wheelchair": "yes"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.686016339687358,
                    48.47063770918534
                ],
                "type": "Point"
            },
            "id": 85832910,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "42",
                "addr:postcode": "3550",
                "addr:street": "Wiener Straße",
                "amenity": "fuel",
                "building": "roof",
                "compressed_air": "yes",
                "key": "amenity",
                "name": "Loistank",
                "source": "Bing",
                "value": "fuel"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.6981845,
                    48.4761165
                ],
                "type": "Point"
            },
            "id": 85891421,
            "properties": {
                "amenity": "fuel",
                "brand": "Avanti",
                "brand:wikidata": "Q168238",
                "brand:wikipedia": "en:OMV",
                "building": "roof",
                "compressed_air": "no",
                "fuel:diesel": "yes",
                "fuel:octane_95": "yes",
                "key": "amenity",
                "name": "Avanti",
                "opening_hours": "24/7",
                "operator": "Avanti",
                "phone": "+43 800 202055",
                "source": "Bing",
                "value": "fuel"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.676249499999997,
                    48.4726905
                ],
                "type": "Point"
            },
            "id": 92839106,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "12",
                "addr:postcode": "3550",
                "addr:street": "Holzplatz",
                "brand": "Billa",
                "brand:wikidata": "Q537781",
                "brand:wikipedia": "en:Billa (supermarket)",
                "building": "yes",
                "contact:phone": "+43 59 9150 3020",
                "key": "shop",
                "name": "Billa",
                "opening_hours": "Mo-Th 07:15-19:00; Fr 07:15-19:30; Sa 07:15-18:00",
                "shop": "supermarket",
                "source": "Bing",
                "value": "supermarket",
                "wheelchair": "yes"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.616307,
                    48.518468
                ],
                "type": "Point"
            },
            "id": 109313701,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "45",
                "addr:postcode": "3553",
                "addr:street": "Obere Straße",
                "at_bev:addr_date": "2021-10-01",
                "building": "yes",
                "castle_type": "chateau",
                "castle_type:de": "schloss",
                "description": "Psychosozialen Zentrum Schiltern",
                "historic": "castle",
                "key": "historic",
                "name": "Schloss Schiltern",
                "value": "castle",
                "website": "https://www.schloss-schiltern.at/",
                "wikidata": "Q27253541",
                "wikimedia_commons": "Category:Schloss Schiltern",
                "wikipedia": "de:Schloss Schiltern"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.654641999999999,
                    48.477014
                ],
                "type": "Point"
            },
            "id": 113507334,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "17",
                "addr:postcode": "3550",
                "addr:street": "Gartenzeile",
                "amenity": "restaurant",
                "building": "yes",
                "contact:phone": "+43 2734 2903",
                "cuisine": "heuriger",
                "key": "cuisine",
                "name": "Heuriger Nastl",
                "opening_hours:url": "https://nastl.at/kontakt/",
                "value": "heuriger",
                "website": "https://www.nastl.at/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.6771175,
                    48.473842000000005
                ],
                "type": "Point"
            },
            "id": 122941745,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "6",
                "addr:postcode": "3550",
                "addr:street": "Kornplatz",
                "building": "yes",
                "key": "shop",
                "name": "Bäckerei Schalk",
                "opening_hours": "Mo-Fr 06:00-12:00,14:30-18:00; Sa 06:00-12:00; PH,Su 07:00-10:30",
                "shop": "bakery",
                "source": "Bing",
                "value": "bakery"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.678989311958695,
                    48.470792232123024
                ],
                "type": "Point"
            },
            "id": 122941751,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "1",
                "addr:postcode": "3550",
                "addr:street": "Dimmelgraben",
                "amenity": "restaurant",
                "building": "yes",
                "check_date:opening_hours": "2021-09-25",
                "cuisine": "regional",
                "key": "cuisine",
                "name": "Weinschlößl Hiedler",
                "opening_hours": "Mo-Fr 16:00-24:00; Sa 13:00-24:00; Su 12:00-24:00",
                "value": "regional"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.675080999999999,
                    48.4718705
                ],
                "type": "Point"
            },
            "id": 122941780,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "11",
                "addr:postcode": "3550",
                "addr:street": "Rudolfstraße",
                "amenity": "doctors",
                "building": "yes",
                "check_date:opening_hours": "2022-10-12",
                "contact:phone": "+43 2734 804 87",
                "healthcare": "doctor",
                "healthcare:speciality": "general",
                "key": "amenity",
                "name": "Dr. Matthias Skopek",
                "opening_hours": "Mo,We 07:30-12:30; Tu 07:30-12:30,17:00-19:00; Fr 07:30-10:30",
                "value": "doctors"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.675145397176165,
                    48.472619754926896
                ],
                "type": "Point"
            },
            "id": 122941785,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "6",
                "addr:postcode": "3550",
                "addr:street": "Holzplatz",
                "amenity": "cafe",
                "at_bev:addr_date": "2020-10-01",
                "building": "yes",
                "cuisine": "cake",
                "key": "amenity",
                "name": "Schneider's Café",
                "operator": "Cobaneshof",
                "outdoor_seating": "yes",
                "value": "cafe",
                "website": "https://www.cobaneshof.at/cafe/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.676936999999999,
                    48.471825
                ],
                "type": "Point"
            },
            "id": 122941802,
            "properties": {
                "addr:city": "Langenlois",
                "addr:housenumber": "6",
                "addr:postcode": "3550",
                "addr:street": "Kirchenplatz",
                "building": "yes",
                "key": "shop",
                "name": "Bäckerei Kirschner",
                "shop": "bakery",
                "source": "survey",
                "value": "bakery"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.675280143710214,
                    48.47270709331516
                ],
                "type": "Point"
            },
            "id": 122941804,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "7",
                "addr:postcode": "3550",
                "addr:street": "Holzplatz",
                "alt_name": "Braugasthaus zum Fiakerwirt",
                "amenity": "restaurant",
                "building": "yes",
                "capacity": "130",
                "cuisine": "regional",
                "key": "cuisine",
                "name": "Gasthaus zum Fiaker-Wirt",
                "outdoor_seating": "yes",
                "phone": "+432734 2150",
                "source": "Bing",
                "value": "regional",
                "website": "https://www.fiakerwirt.at/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.677086690869768,
                    48.47299928996345
                ],
                "type": "Point"
            },
            "id": 126978428,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "10",
                "addr:postcode": "3550",
                "addr:street": "Rathausstraße",
                "amenity": "cafe",
                "building": "yes",
                "key": "amenity",
                "name": "Rathauscafe",
                "opening_hours": "Mo,Tu,Th,Fr 07:30-18:00; Sa 07:30-18:00; Su 09:30-19:00",
                "outdoor_seating": "yes",
                "source": "Bing",
                "value": "cafe"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.676824156051673,
                    48.47409888094241
                ],
                "type": "Point"
            },
            "id": 126978455,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "3",
                "addr:postcode": "3550",
                "addr:street": "Kamptalstraße",
                "amenity": "bicycle_rental",
                "building": "yes",
                "information": "office",
                "key": "information",
                "name": "Ursin Haus",
                "opening_hours": "PH,Mo-Su 10:00-18:00",
                "shop": "alcohol",
                "source": "Bing",
                "tourism": "information",
                "value": "office",
                "website": "https://www.ursinhaus.at/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.676462,
                    48.4734635
                ],
                "type": "Point"
            },
            "id": 126978488,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "2",
                "addr:postcode": "3550",
                "addr:street": "Kornplatz",
                "alt_name": "s'Wirtshaus am Kornplatz",
                "amenity": "restaurant",
                "at_bev:addr_date": "2021-04-01",
                "building": "yes",
                "cuisine": "regional",
                "key": "cuisine",
                "leisure": "outdoor_seating",
                "name": "Restaurant Langenloiser Hof",
                "opening_hours": "Mo,Tu,Fr 09:00-15:00,18:00-22:00; Sa 09:00-22:00; Su,PH 09:00-16:00; We,Th off",
                "outdoor_seating": "yes",
                "phone": "+43 2734 2475",
                "source": "Bing;survey",
                "value": "regional",
                "website": "https://www.wirtshaus-langenlois.at/",
                "wikidata": "Q37957301"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.617668713337768,
                    48.51988400151543
                ],
                "type": "Point"
            },
            "id": 134150856,
            "properties": {
                "access": "customers",
                "description": "Jungpflanzen, Saatgut seltener Gemüse- und Nutzpflanzen sowie Gartengeräte, Bücher und Geschenke",
                "key": "tourism",
                "leisure": "garden",
                "name": "Arche Noah Schaugarten",
                "opening_hours": "Tu-Fr 10:00-16:00; Sa-Su 10:00-17:00",
                "source": "geoimage.at hires",
                "tourism": "attraction",
                "url": "http://www.arche-noah.at",
                "value": "attraction",
                "wikidata": "Q632519",
                "wikipedia": "de:Arche Noah (Verein)"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.673194,
                    48.468303500000005
                ],
                "type": "Point"
            },
            "id": 163593056,
            "properties": {
                "key": "tourism",
                "leisure": "garden",
                "name": "Lehr- und Schaugarten",
                "tourism": "attraction",
                "value": "attraction"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.70094375419955,
                    48.51337978866974
                ],
                "type": "Point"
            },
            "id": 165898059,
            "properties": {
                "alt_name": "Burgruine Schönberg",
                "building": "yes",
                "building:condition": "completely_ruinous",
                "castle_type": "defensive",
                "castle_type:de": "burgruine",
                "heritage": "2",
                "heritage:operator": "bda",
                "historic": "castle",
                "historic:civilization": "medieval",
                "key": "historic",
                "name": "Burgruine Schonenburg",
                "ref:at:bda": "70999",
                "ruins": "yes",
                "value": "castle",
                "wikidata": "Q38087857",
                "wikimedia_commons": "Category:Burgruine Schonenburg",
                "wikipedia": "de:Burgruine Schonenburg"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.696327521276478,
                    48.516241870521554
                ],
                "type": "Point"
            },
            "id": 210272920,
            "properties": {
                "addr:city": "Schönberg",
                "addr:country": "AT",
                "addr:housenumber": "12",
                "addr:postcode": "3562",
                "addr:street": "Kamptalstraße",
                "at_bev:addr_date": "2017-10-01",
                "building": "retail",
                "building:levels": "1",
                "key": "shop",
                "name": "Nah & Frisch",
                "opening_hours": "Mo-Fr 06:30-13:00, 15:00-18:00;Sa 06:30-13:00",
                "operator": "Plabensteiner",
                "phone": "+43 2733 76422",
                "roof:levels": "0",
                "roof:shape": "skillion",
                "shop": "supermarket",
                "value": "supermarket"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.603588009603023,
                    48.511435778484326
                ],
                "type": "Point"
            },
            "id": 222451282,
            "properties": {
                "building": "yes",
                "building:condition": "mainly_ruinous",
                "castle_type": "defensive",
                "castle_type:de": "burgruine",
                "heritage": "2",
                "heritage:operator": "bda",
                "historic": "castle",
                "historic:civilization": "medieval",
                "key": "historic",
                "name": "Burgruine Kronsegg",
                "ref:at:bda": "70998",
                "ruins": "yes",
                "value": "castle",
                "wikidata": "Q27237332",
                "wikimedia_commons": "Category:Burgruine_Kronsegg",
                "wikipedia": "de:Burgruine Kronsegg"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.692067,
                    48.53487
                ],
                "type": "Point"
            },
            "id": 257682154,
            "properties": {
                "addr:city": "Stiefern",
                "addr:country": "AT",
                "addr:housenumber": "1",
                "addr:postcode": "3562",
                "addr:street": "Hauptstraße",
                "amenity": "restaurant",
                "building": "yes",
                "cuisine": "regional",
                "key": "cuisine",
                "name": "Gasthof Haimerl",
                "opening_hours": "Th off",
                "value": "regional"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.605281999999999,
                    48.497718
                ],
                "type": "Point"
            },
            "id": 352479215,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "41",
                "addr:place": "Mittelberg",
                "addr:postcode": "3550",
                "amenity": "restaurant",
                "building": "yes",
                "contact:email": "office@weingut-am-berg.at",
                "contact:phone": "+43 2734 2965",
                "craft": "winery",
                "cuisine": "heuriger",
                "key": "cuisine",
                "name": "Weingut am Berg",
                "value": "heuriger",
                "website": "http://weingut-am-berg.at"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.677772887503426,
                    48.471346689526804
                ],
                "type": "Point"
            },
            "id": 384869924,
            "properties": {
                "key": "leisure",
                "leisure": "playground",
                "value": "playground"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.697821000000001,
                    48.517778
                ],
                "type": "Point"
            },
            "id": 389917229,
            "properties": {
                "addr:city": "Schönberg am Kamp",
                "addr:housenumber": "18",
                "addr:postcode": "3562",
                "addr:street": "Hauptstraße",
                "amenity": "cafe",
                "building": "yes",
                "email": "office@papperl-a-pub.at",
                "key": "amenity",
                "name": "Papperl á Pub",
                "opening_hours": "Mo, Tu, Th, Fr 16:00+; Sa,Su 10:00+",
                "phone": "+43 2733 61352",
                "smoking": "isolated",
                "value": "cafe",
                "website": "https://www.papperl-a-pub.at/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.627800499999996,
                    48.516549499999996
                ],
                "type": "Point"
            },
            "id": 411069556,
            "properties": {
                "key": "leisure",
                "leisure": "playground",
                "value": "playground"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.628615499999999,
                    48.5142125
                ],
                "type": "Point"
            },
            "id": 412077708,
            "properties": {
                "addr:city": "Schiltern",
                "addr:country": "AT",
                "addr:housenumber": "27",
                "addr:postcode": "3553",
                "addr:street": "Untere Straße",
                "amenity": "restaurant",
                "at_bev:addr_date": "2020-04-01",
                "building": "house",
                "cuisine": "heuriger",
                "email": "heuriger@mayerei.at",
                "key": "cuisine",
                "name": "Heuriger Mayerei",
                "opening_hours": "Jul 01-Sep 10,Oct 12-Dec 23 Th-Mo 16:00+",
                "opening_hours:url": "http://www.mayerei.at/Heuriger_Mayerei/Uber_Uns.html",
                "operator": "Christina Mayer",
                "phone": "+43 664 915 20 20",
                "value": "heuriger",
                "website": "http://www.mayerei.at"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.6633465,
                    48.4746665
                ],
                "type": "Point"
            },
            "id": 446990400,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "38",
                "addr:postcode": "3550",
                "addr:street": "Zwettler Straße",
                "building": "yes",
                "email": "baeckerei.schalk@aon.at",
                "fax": "0043 2734 2202 8",
                "key": "shop",
                "name": "Schalk",
                "opening_hours": "Mo,Tu,Th,Fr 06:00-12:00,14:30-18:00; We,Sa 06:00-12:00; PH off",
                "phone": "0043 2734 2202",
                "shop": "bakery",
                "value": "bakery",
                "website": "https://baeckerei-schalk.at/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.621568533767864,
                    48.517521741823344
                ],
                "type": "Point"
            },
            "id": 499072093,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "18",
                "addr:postcode": "3553",
                "addr:street": "Obere Straße",
                "addr:unit": "1",
                "amenity": "restaurant",
                "at_bev:addr_date": "2021-10-01",
                "building": "yes",
                "cuisine": "regional",
                "key": "cuisine",
                "name": "Gabi's Weinschenke",
                "todo": "correct building",
                "value": "regional"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.626404754556845,
                    48.513378583037834
                ],
                "type": "Point"
            },
            "id": 499336719,
            "properties": {
                "addr:city": "Schiltern",
                "addr:country": "AT",
                "addr:housenumber": "18",
                "addr:postcode": "3553",
                "addr:street": "Kellergasse",
                "amenity": "restaurant",
                "at_bev:addr_date": "2020-04-01",
                "building": "yes",
                "cuisine": "heuriger",
                "key": "cuisine",
                "value": "heuriger"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.698253482704958,
                    48.48122059457952
                ],
                "type": "Point"
            },
            "id": 518904040,
            "properties": {
                "key": "leisure",
                "leisure": "playground",
                "value": "playground"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.624989,
                    48.5170865
                ],
                "type": "Point"
            },
            "id": 523665188,
            "properties": {
                "building": "yes",
                "building:condition": "mainly_ruinous",
                "castle_type": "defensive",
                "castle_type:de": "burgruine",
                "historic": "castle",
                "historic:civilization": "medieval",
                "key": "historic",
                "name": "Burgruine Schiltern",
                "ruins": "castle",
                "value": "castle",
                "wikidata": "Q37829855",
                "wikimedia_commons": "File:Schiltern_-_Hausberg_Zorimauer.jpg",
                "wikipedia": "de:Hausberg Zorimauer"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.683048231516548,
                    48.55603517774284
                ],
                "type": "Point"
            },
            "id": 593621674,
            "properties": {
                "key": "leisure",
                "leisure": "playground",
                "value": "playground"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.608172764513,
                    48.47100352581003
                ],
                "type": "Point"
            },
            "id": 665583256,
            "properties": {
                "access": "yes",
                "key": "leisure",
                "leisure": "playground",
                "value": "playground"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.67020497985782,
                    48.47766214316745
                ],
                "type": "Point"
            },
            "id": 667850448,
            "properties": {
                "access": "customers",
                "key": "leisure",
                "leisure": "playground",
                "operator": "Fam. Köstler",
                "value": "playground"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.672857,
                    48.514818999999996
                ],
                "type": "Point"
            },
            "id": 744569220,
            "properties": {
                "access": "yes",
                "key": "leisure",
                "leisure": "playground",
                "value": "playground"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.674511423237027,
                    48.47882809124145
                ],
                "type": "Point"
            },
            "id": 777851799,
            "properties": {
                "key": "leisure",
                "leisure": "playground",
                "source": "basemap.at",
                "value": "playground"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.671356041009288,
                    48.472351894180264
                ],
                "type": "Point"
            },
            "id": 839541024,
            "properties": {
                "access": "private",
                "key": "leisure",
                "leisure": "playground",
                "value": "playground"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.628821161129553,
                    48.51037092872945
                ],
                "type": "Point"
            },
            "id": 978839432,
            "properties": {
                "key": "leisure",
                "leisure": "playground",
                "value": "playground"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.62947345176956,
                    48.51021648684262
                ],
                "type": "Point"
            },
            "id": 978839433,
            "properties": {
                "access": "customers",
                "key": "leisure",
                "leisure": "playground",
                "value": "playground"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.698084081643264,
                    48.481109263130534
                ],
                "type": "Point"
            },
            "id": 996042781,
            "properties": {
                "amenity": "cafe",
                "building": "yes",
                "key": "amenity",
                "name": "Kampbuffet",
                "outdoor_seating": "yes",
                "value": "cafe"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.69330749999999,
                    48.5145115
                ],
                "type": "Point"
            },
            "id": 1093431686,
            "properties": {
                "addr:city": "Schönberg",
                "addr:country": "AT",
                "addr:housenumber": "9",
                "addr:postcode": "3562",
                "addr:street": "Mollandserstraße",
                "email": "gaertner@straussenland.at",
                "key": "tourism",
                "landuse": "farmyard",
                "name": "Straußenland",
                "operator": "Fam. Gärtner",
                "phone": "+43 2733 8224",
                "tourism": "attraction",
                "value": "attraction",
                "website": "https://www.straussenland.at/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.592524504717456,
                    48.473363069620376
                ],
                "type": "Point"
            },
            "id": 1219553899,
            "properties": {
                "key": "leisure",
                "leisure": "playground",
                "value": "playground"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.652800433294725,
                    48.477252203040486
                ],
                "type": "Point"
            },
            "id": 1231638219,
            "properties": {
                "key": "leisure",
                "leisure": "playground",
                "value": "playground"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.676221,
                    48.472193
                ],
                "type": "Point"
            },
            "id": 443006286,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "1",
                "addr:postcode": "3550",
                "addr:street": "Holzplatz",
                "amenity": "pharmacy",
                "dispensing": "yes",
                "key": "amenity",
                "name": "Adler Apotheke Langenlois",
                "opening_hours": "Mo-Fr 08:00-18:00; Sa 08:00-12:30",
                "value": "pharmacy"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.693698,
                    48.490421
                ],
                "type": "Point"
            },
            "id": 513111068,
            "properties": {
                "addr:city": "Zöbing",
                "addr:housenumber": "15",
                "addr:postcode": "3561",
                "addr:street": "Marktplatz",
                "amenity": "restaurant",
                "cuisine": "regional",
                "key": "cuisine",
                "name": "Heiligensteinerhof",
                "phone": "+43 2734 27 69-0",
                "value": "regional",
                "website": "https://www.zoebing.at/heiligensteinerhof/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.698569,
                    48.492116
                ],
                "type": "Point"
            },
            "id": 513111070,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "32",
                "addr:postcode": "3561",
                "addr:street": "Heiligensteinstraße",
                "amenity": "restaurant",
                "at_bev:addr_date": "2020-10-01",
                "cuisine": "regional",
                "key": "cuisine",
                "name": "Gutmann",
                "opening_hours": "We-Sa 11:30-14:30, 18:00-20:00; PH, Su 09:00-18:00",
                "phone": "+43 2734 2334",
                "value": "regional",
                "website": "https://www.gasthaus-gutmann.com/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.687143,
                    48.471392
                ],
                "type": "Point"
            },
            "id": 587730901,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "2",
                "addr:postcode": "3550",
                "addr:street": "Hollerweg",
                "amenity": "doctors",
                "key": "amenity",
                "name": "Dr. Larissa Trybus",
                "opening_hours": "Mo,We,Th,Fr 08:00-12:30; Mo 17:00-19:00",
                "value": "doctors"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.676022,
                    48.473058
                ],
                "type": "Point"
            },
            "id": 598253760,
            "properties": {
                "amenity": "post_box",
                "check_date:collection_times": "2022-11-16",
                "collection_times": "Mo-Fr 17:00",
                "key": "amenity",
                "operator": "Österreichische Post AG",
                "value": "post_box"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.692158,
                    48.4712
                ],
                "type": "Point"
            },
            "id": 996851035,
            "properties": {
                "amenity": "fuel",
                "brand": "Genol",
                "compressed_air": "no",
                "fuel:diesel": "yes",
                "key": "amenity",
                "opening_hours": "24/7",
                "shop": "no",
                "value": "fuel"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.697712,
                    48.516827
                ],
                "type": "Point"
            },
            "id": 1338191558,
            "properties": {
                "designation": "Stoamandlpark",
                "key": "leisure",
                "leisure": "playground",
                "name": "Stoamandlpark",
                "value": "playground"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.691543,
                    48.517869
                ],
                "type": "Point"
            },
            "id": 1338226502,
            "properties": {
                "amenity": "restaurant",
                "cuisine": "regional",
                "key": "cuisine",
                "name": "Weingärtnerei Aichinger",
                "value": "regional"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.695411,
                    48.514297
                ],
                "type": "Point"
            },
            "id": 1338230916,
            "properties": {
                "amenity": "fuel",
                "compressed_air": "yes",
                "fuel:diesel": "yes",
                "fuel:octane_95": "yes",
                "key": "amenity",
                "name": "Esso Lindermayer",
                "value": "fuel"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.575601,
                    48.464326
                ],
                "type": "Point"
            },
            "id": 1365272354,
            "properties": {
                "addr:city": "Droß",
                "addr:country": "AT",
                "addr:housenumber": "53",
                "addr:postcode": "3552",
                "addr:street": "Am Platzl",
                "key": "shop",
                "name": "Bäckerei Kafesy",
                "phone": "+43 02719 301 53",
                "shop": "bakery",
                "value": "bakery",
                "website": "https://www.kafesy.at/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.676892,
                    48.473794
                ],
                "type": "Point"
            },
            "id": 1829944769,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "5",
                "addr:postcode": "3550",
                "addr:street": "Kornplatz",
                "amenity": "cafe",
                "key": "amenity",
                "name": "Café & Wein",
                "opening_hours": "Tu-Sa 09:00-23:00; PH,Su,Mo 09:00-18:00",
                "value": "cafe",
                "website": "https://www.ursinhaus.at/cafeweinbar.html"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.666929,
                    48.474149
                ],
                "type": "Point"
            },
            "id": 2078458868,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "11",
                "addr:postcode": "3550",
                "addr:street": "Zwettler Straße",
                "fixme": "position",
                "key": "tourism",
                "name": "Privatmuseum Steininger",
                "tourism": "museum",
                "value": "museum"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.688863,
                    48.474465
                ],
                "type": "Point"
            },
            "id": 2260247237,
            "properties": {
                "amenity": "post_box",
                "check_date": "2020-12-26",
                "check_date:collection_times": "2023-01-30",
                "collection_times": "Mo-Fr 09:00",
                "key": "amenity",
                "value": "post_box"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.698583,
                    48.471577
                ],
                "type": "Point"
            },
            "id": 2260257100,
            "properties": {
                "amenity": "post_box",
                "key": "amenity",
                "value": "post_box"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.576698,
                    48.462512
                ],
                "type": "Point"
            },
            "id": 3749218955,
            "properties": {
                "addr:city": "Droß",
                "addr:country": "AT",
                "addr:housenumber": "81",
                "addr:postcode": "3552",
                "addr:street": "Hauptstraße",
                "amenity": "restaurant",
                "cuisine": "regional",
                "delivery": "no",
                "key": "cuisine",
                "name": "Zum alten Bierkeller",
                "opening_hours": "We off",
                "outdoor_seating": "yes",
                "phone": "+43 2719 8296",
                "smoking": "isolated",
                "value": "regional"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.574181,
                    48.464677
                ],
                "type": "Point"
            },
            "id": 3749386051,
            "properties": {
                "addr:city": "Droß",
                "addr:country": "AT",
                "addr:housenumber": "27",
                "addr:postcode": "3552",
                "addr:street": "Schloßstraße",
                "amenity": "doctors",
                "email": "daniela@grulich.at",
                "healthcare:speciality": "general",
                "healthcare:speciality:de": "Arzt für Allgemeinmedizin",
                "key": "amenity",
                "name": "Dr. Daniela Grulich",
                "phone": "+43 650 5310925",
                "value": "doctors",
                "website": "http://www.daniela.grulich.at"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.574111,
                    48.464575
                ],
                "type": "Point"
            },
            "id": 3749386065,
            "properties": {
                "addr:city": "Droß",
                "addr:country": "AT",
                "addr:housenumber": "27",
                "addr:postcode": "3552",
                "addr:street": "Schloßstraße",
                "amenity": "doctors",
                "healthcare:speciality": "general",
                "healthcare:speciality:de": "Arzt für Allgemeinmedizin",
                "key": "amenity",
                "name": "Dr. Ursula Ernst",
                "phone": "+43 664 420 56 00",
                "value": "doctors"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.572658,
                    48.469425
                ],
                "type": "Point"
            },
            "id": 3749433297,
            "properties": {
                "addr:city": "Droß",
                "addr:country": "AT",
                "addr:housenumber": "44",
                "addr:postcode": "3552",
                "addr:street": "Schäferhof",
                "amenity": "doctors",
                "contact:mobile": "+43664 281 92 71",
                "contact:phone": "+432719 94121",
                "email": "h-winkler@aon.at",
                "healthcare:speciality": "orthopaedics",
                "healthcare:speciality:de": "Facharzt für Orthopädie und Orthopädische Chirurgie",
                "key": "amenity",
                "name": "Dr. Heinz Winkler - Orthopaedie Schaeferhof",
                "value": "doctors",
                "website": "http://www.osteomyelitis.at"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.630745,
                    48.510882
                ],
                "type": "Point"
            },
            "id": 3773269410,
            "properties": {
                "key": "tourism",
                "name": "Arena",
                "tourism": "attraction",
                "value": "attraction"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.630012,
                    48.509959
                ],
                "type": "Point"
            },
            "id": 3773269411,
            "properties": {
                "key": "tourism",
                "name": "Kräuterspirale",
                "toilets:wheelchair": "no",
                "tourism": "attraction",
                "value": "attraction",
                "wheelchair": "yes"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.629783,
                    48.510332
                ],
                "type": "Point"
            },
            "id": 3773269412,
            "properties": {
                "key": "tourism",
                "name": "Mensch ärgere dich nicht",
                "tourism": "attraction",
                "value": "attraction"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.629593,
                    48.510439
                ],
                "type": "Point"
            },
            "id": 3773270340,
            "properties": {
                "key": "tourism",
                "name": "Strohpyramide",
                "tourism": "attraction",
                "value": "attraction"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.678505,
                    48.555879
                ],
                "type": "Point"
            },
            "id": 3920512200,
            "properties": {
                "addr:city": "Plank",
                "addr:country": "AT",
                "addr:housenumber": "38",
                "addr:postcode": "3564",
                "addr:street": "Kamptalstraße",
                "amenity": "doctors",
                "key": "amenity",
                "name": "Ordination Plank",
                "opening_hours": "Tu 16:00-18:00; Th 08:00-12:00",
                "value": "doctors"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.696158,
                    48.51993
                ],
                "type": "Point"
            },
            "id": 3928892203,
            "properties": {
                "addr:city": "Schönberg am Kamp",
                "addr:country": "AT",
                "addr:housenumber": "12",
                "addr:postcode": "3562",
                "addr:street": "Badgasse",
                "amenity": "doctors",
                "healthcare": "doctor",
                "healthcare:speciality": "emergency;anaesthetics;general_practice",
                "key": "amenity",
                "name": "Dr. Tschiesche",
                "note": "Arzt mit Hausapotheke",
                "opening_hours": "Mo,Tu,Fr 08:00-12:00; Th 16:00-18:00",
                "pharmacy": "yes",
                "source": "survey;local knowledge",
                "value": "doctors",
                "website": "http://www.kamptaldoktor.at/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.689934,
                    48.528607
                ],
                "type": "Point"
            },
            "id": 3983597739,
            "properties": {
                "image": "http://www.smartstyria.at/wp-content/gallery/mondrohr/179793_623314011013664_923427003_n.jpg",
                "key": "tourism",
                "lit": "yes",
                "name": "Mondrohr",
                "tourism": "attraction",
                "value": "attraction"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.681958,
                    48.474342
                ],
                "type": "Point"
            },
            "id": 4068249810,
            "properties": {
                "amenity": "post_office",
                "brand": "DPD Paketshop",
                "brand:wikidata": "Q541030",
                "brand:wikipedia": "en:DPDgroup",
                "key": "amenity",
                "name": "DPD Paketshop",
                "opening_hours": "Mo-Fr 09:00-13:00,14:00-18:00",
                "operator": "Silverweb",
                "value": "post_office"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.69746,
                    48.517461
                ],
                "type": "Point"
            },
            "id": 4282512735,
            "properties": {
                "amenity": "post_box",
                "collection_times": "Mo-Fr 9:30",
                "key": "amenity",
                "operator": "Österreichische Post AG",
                "ref": "3550-9060",
                "value": "post_box"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.617022,
                    48.518865
                ],
                "type": "Point"
            },
            "id": 4293410292,
            "properties": {
                "amenity": "atm",
                "key": "amenity",
                "value": "atm"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.617967,
                    48.520464
                ],
                "type": "Point"
            },
            "id": 4293476390,
            "properties": {
                "key": "tourism",
                "name": "Arche Noah Streuobstwiese",
                "tourism": "attraction",
                "value": "attraction"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.617747,
                    48.519043
                ],
                "type": "Point"
            },
            "id": 4293481989,
            "properties": {
                "addr:housenumber": "40",
                "addr:postcode": "3553",
                "addr:street": "Obere Straße",
                "description": "Jungpflanzen, Saatgut seltener Gemüse- und Nutzpflanzen sowie Gartengeräte, Bücher und Geschenke",
                "key": "tourism",
                "name": "Arche Noah Shop",
                "opening_hours": "Tu-Fr 10:00-22:00; Sa-Su 10:00-17:00",
                "tourism": "attraction",
                "value": "attraction",
                "website": "https://www.arche-noah.at/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.616937,
                    48.518825
                ],
                "type": "Point"
            },
            "id": 4396474432,
            "properties": {
                "amenity": "cafe",
                "key": "amenity",
                "name": "Schloss Cafe",
                "value": "cafe"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.602397,
                    48.472507
                ],
                "type": "Point"
            },
            "id": 4839699508,
            "properties": {
                "amenity": "cafe",
                "key": "amenity",
                "name": "Velino",
                "value": "cafe"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.632929,
                    48.51232
                ],
                "type": "Point"
            },
            "id": 5046159249,
            "properties": {
                "addr:city": "Schiltern",
                "addr:postcode": "3553",
                "addr:street": "Laabergstraße",
                "alt_name": "Brau Schneider",
                "craft": "brewery",
                "fixme": "building and more details",
                "key": "craft",
                "name": "BrauSchneider",
                "opening_hours": "Mo-Fr 09:00-17:00",
                "operator": "Schneider",
                "phone": "+43 2734 32917",
                "product": "beer",
                "value": "brewery",
                "website": "https://www.brauschneider.at/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.616776,
                    48.518864
                ],
                "type": "Point"
            },
            "id": 5046168925,
            "properties": {
                "addr:city": "Schiltern",
                "addr:country": "AT",
                "addr:housenumber": "45",
                "addr:postcode": "3553",
                "addr:street": "Obere Straße",
                "fee": "yes",
                "key": "tourism",
                "name": "Modellbahnwelt-Schiltern",
                "opening_hours": "We-Mo 10:00-18:00; Tu off",
                "tourism": "attraction",
                "value": "attraction",
                "website": "https://www.modellbahnwelt-schiltern.at/",
                "wheelchair": "no"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.674411,
                    48.472395
                ],
                "type": "Point"
            },
            "id": 5078603597,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "1",
                "addr:postcode": "3550",
                "addr:street": "Kaserngasse",
                "amenity": "restaurant",
                "at_bev:addr_date": "2017-04-07",
                "cuisine": "regional",
                "key": "cuisine",
                "name": "Liubisa",
                "opening_hours": "Tu-Th 16:00-01:00, Fr,Sa 16:00-02:00",
                "phone": "+43 650 9991893",
                "smoking": "outside",
                "source": "geoimage.at maxres",
                "value": "regional",
                "website": "https://www.facebook.com/LiubisaLangenlois/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.693291,
                    48.51463
                ],
                "type": "Point"
            },
            "id": 5085350674,
            "properties": {
                "addr:city": "Schönberg",
                "addr:country": "AT",
                "addr:housenumber": "9",
                "addr:postcode": "3562",
                "addr:street": "Mollandserstraße",
                "email": "gaertner@straussenland.at",
                "key": "tourism",
                "name": "Straußenland",
                "opening_hours": "Jan-Feb: Mo-Sa 08:00-12:00; Mar: Mo-Fr 08:00-12:00,14:00-17:00; Mar: Sa 08:00-12:00; Apr-Oct: Mo-Sa 08:00-12:00,14:00-17:00; Apr-Oct: Su,PH 14:00-17:00; Nov-Dec: Mo-Fr 08:00-12:00,14:00-17:00; Nov-Dec: Sa 08:00-12:00",
                "phone": "+4327338224",
                "shop": "farm",
                "tourism": "attraction",
                "value": "attraction",
                "website": "https://www.straussenland.at/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.630069,
                    48.510428
                ],
                "type": "Point"
            },
            "id": 5178024509,
            "properties": {
                "key": "tourism",
                "name": "Schach",
                "tourism": "attraction",
                "value": "attraction"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.630864,
                    48.509785
                ],
                "type": "Point"
            },
            "id": 5178115048,
            "properties": {
                "key": "tourism",
                "name": "Labyrinth",
                "tourism": "attraction",
                "value": "attraction"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.575068,
                    48.463906
                ],
                "type": "Point"
            },
            "id": 5554513758,
            "properties": {
                "addr:city": "Droß",
                "addr:housenumber": "73",
                "addr:postcode": "3552",
                "addr:street": "Am Platzl",
                "alt_name": "Winzerhof am Platzl",
                "amenity": "restaurant",
                "cuisine": "heuriger",
                "email": "buschenschank_nigl@aon.at",
                "key": "cuisine",
                "name": "Heuriger Nigl Ingeborg",
                "outdoor_seating": "yes",
                "phone": "+43 2719 8524",
                "shop": "wine",
                "value": "heuriger",
                "website": "http://wein-nigl.at"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.571098,
                    48.466279
                ],
                "type": "Point"
            },
            "id": 5554693157,
            "properties": {
                "addr:city": "Droß",
                "addr:country": "AT",
                "addr:housenumber": "291",
                "addr:postcode": "3552",
                "addr:street": "Siedlungsgasse",
                "amenity": "doctors",
                "healthcare": "doctor",
                "healthcare:speciality": "gynaecology",
                "key": "amenity",
                "name": "Dr. Ewald Aigner",
                "value": "doctors"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.650517,
                    48.479069
                ],
                "type": "Point"
            },
            "id": 5864973672,
            "properties": {
                "building": "yes",
                "building:condition": "completely_ruinous",
                "castle_type": "defensive",
                "castle_type:de": "burgruine",
                "fixme": "no wikidata object",
                "historic": "castle",
                "historic:civilization": "medieval",
                "key": "historic",
                "name": "Ruine Taubenfang",
                "ruins": "yes",
                "value": "castle"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.670152,
                    48.477973
                ],
                "type": "Point"
            },
            "id": 6253451785,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "54",
                "addr:postcode": "3550",
                "addr:street": "Schilterner Straße",
                "amenity": "restaurant",
                "contact:phone": "+43 664 103 92 62",
                "cuisine": "heuriger",
                "key": "cuisine",
                "name": "Heuriger zur langen Sonne",
                "operator": "Fam. Köstler",
                "outdoor_seating": "yes",
                "phone": "+436641039262   +436644107601",
                "value": "heuriger",
                "website": "https://www.zur-langen-sonne.at/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.682661,
                    48.474404
                ],
                "type": "Point"
            },
            "id": 6610314575,
            "properties": {
                "addr:city": "Langenlois",
                "addr:country": "AT",
                "addr:housenumber": "36",
                "addr:postcode": "3550",
                "addr:street": "Bahnstraße",
                "amenity": "doctors",
                "healthcare": "doctor",
                "key": "amenity",
                "name": "Dr. Enikö Meszaros",
                "opening_hours": "Mo,Tu,Th,Fr 07:30-12:00; Th 16:30-18:30",
                "value": "doctors"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.695025,
                    48.522097
                ],
                "type": "Point"
            },
            "id": 6934695499,
            "properties": {
                "amenity": "restaurant",
                "cuisine": "regional",
                "key": "cuisine",
                "name": "Badbuffet",
                "value": "regional"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.596001,
                    48.471723
                ],
                "type": "Point"
            },
            "id": 7430682977,
            "properties": {
                "addr:city": "Lengenfeld",
                "addr:country": "AT",
                "addr:housenumber": "17a",
                "addr:postcode": "3552",
                "addr:street": "Langenloiser Straße",
                "amenity": "doctors",
                "healthcare": "doctor",
                "healthcare:speciality": "general",
                "key": "amenity",
                "name": "Dr. Rainer Ludhammer",
                "phone": "+43 2719 78587",
                "value": "doctors",
                "website": "https://praxis-ludhammer.com/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.6604,
                    48.475926
                ],
                "type": "Point"
            },
            "id": 8146215072,
            "properties": {
                "amenity": "post_box",
                "collection_times": "Mo-Fr 09:00",
                "key": "amenity",
                "value": "post_box"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.676178,
                    48.471197
                ],
                "type": "Point"
            },
            "id": 8559845586,
            "properties": {
                "addr:city": "Langenlois",
                "addr:housenumber": "9",
                "addr:postcode": "3550",
                "addr:street": "Kremser Straße",
                "amenity": "doctors",
                "healthcare": "doctor",
                "healthcare:speciality": "dermatology",
                "key": "amenity",
                "name": "Dr. Karin Raimitz Kreyer",
                "opening_hours": "\"nach telefonischer Vereinbarung\"",
                "phone": "+43 2734 32 004",
                "value": "doctors",
                "website": "http://www.raimitz-kreyer.at/index.php"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.606843,
                    48.472791
                ],
                "type": "Point"
            },
            "id": 8757815696,
            "properties": {
                "addr:city": "Lengenfeld",
                "addr:country": "AT",
                "addr:housenumber": "83",
                "addr:postcode": "3552",
                "addr:street": "Langenloiser Straße",
                "amenity": "restaurant",
                "cuisine": "heuriger",
                "key": "cuisine",
                "name": "Heuriger Völkl",
                "opening_hours": "\"Siehe Heurigentermine auf Website\"",
                "outdoor_seating": "yes",
                "phone": "+436769186121",
                "value": "heuriger",
                "website": "https://www.weingut-voelkl.at"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.688569,
                    48.532794
                ],
                "type": "Point"
            },
            "id": 9056578296,
            "properties": {
                "amenity": "restaurant",
                "cuisine": "heuriger",
                "delivery": "no",
                "key": "cuisine",
                "name": "Heuriger Staritzbichler-Deibler",
                "outdoor_seating": "yes",
                "value": "heuriger"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.629558,
                    48.509973
                ],
                "type": "Point"
            },
            "id": 9057098626,
            "properties": {
                "amenity": "cafe",
                "key": "amenity",
                "value": "cafe"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.604869,
                    48.498266
                ],
                "type": "Point"
            },
            "id": 9703621547,
            "properties": {
                "amenity": "post_box",
                "collection_times": "Mo-Fr 11:00",
                "key": "amenity",
                "value": "post_box"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.669805,
                    48.47447
                ],
                "type": "Point"
            },
            "id": 9703750095,
            "properties": {
                "amenity": "post_box",
                "collection_times": "Mo-Fr 09:30",
                "key": "amenity",
                "value": "post_box"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.691246,
                    48.473011
                ],
                "type": "Point"
            },
            "id": 10047411923,
            "properties": {
                "access": "yes",
                "dog": "no",
                "indoor": "no",
                "key": "leisure",
                "leisure": "playground",
                "name": "Europapark",
                "playground:theme": "playground",
                "source": "local knowledge",
                "surface": "grass",
                "value": "playground",
                "wheelchair": "no"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.572888,
                    48.464726
                ],
                "type": "Point"
            },
            "id": 10216555829,
            "properties": {
                "amenity": "atm",
                "currency:EUR": "yes",
                "drive_through": "no",
                "key": "amenity",
                "value": "atm"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.677433,
                    48.473595
                ],
                "type": "Point"
            },
            "id": 10216571877,
            "properties": {
                "amenity": "atm",
                "currency:EUR": "yes",
                "drive_through": "no",
                "key": "amenity",
                "value": "atm"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.676244,
                    48.471738
                ],
                "type": "Point"
            },
            "id": 10219802047,
            "properties": {
                "addr:city": "Langenlois",
                "addr:housenumber": "1",
                "addr:postcode": "3550",
                "addr:street": "Rudolfstraße",
                "amenity": "doctors",
                "healthcare": "doctor",
                "healthcare:speciality": "gynaecology",
                "key": "amenity",
                "name": "OA Dr. Franz Burger",
                "opening_hours": "Tu 15:00-18:00; Fr 09:00-12:00; PH off",
                "operator": "OA Dr. Franz Burger",
                "phone": "+43 2734 3370",
                "value": "doctors"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.658546,
                    48.476506
                ],
                "type": "Point"
            },
            "id": 10836687763,
            "properties": {
                "addr:city": "Langenlois",
                "addr:housenumber": "8",
                "addr:postcode": "3550",
                "addr:street": "Mühlgasse",
                "amenity": "restaurant",
                "contact:email": "steiner@hauermandl.at",
                "cuisine": "heuriger",
                "key": "cuisine",
                "name": "Heuriger",
                "value": "heuriger",
                "website": "https://www.hauermandl.at/heurigenlokal.html"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.600244,
                    48.46945
                ],
                "type": "Point"
            },
            "id": 11300273192,
            "properties": {
                "key": "tourism",
                "name": "Baumpresse",
                "opening_hours": "24/7",
                "tourism": "attraction",
                "value": "attraction"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.595484,
                    48.471519
                ],
                "type": "Point"
            },
            "id": 11329537621,
            "properties": {
                "addr:city": "Lengenfeld",
                "addr:country": "AT",
                "addr:housenumber": "15",
                "addr:postcode": "3552",
                "addr:street": "Langenloiser Straße",
                "amenity": "restaurant",
                "cuisine": "regional",
                "key": "cuisine",
                "mobile": "+43 676 506 55 71",
                "name": "Gasthaus Anderl",
                "value": "regional"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.602006,
                    48.472027
                ],
                "type": "Point"
            },
            "id": 11329537623,
            "properties": {
                "addr:city": "Lengenfeld",
                "addr:country": "AT",
                "addr:housenumber": "67",
                "addr:postcode": "3552",
                "addr:street": "Langenloiser Straße",
                "brand": "Nah & Frisch",
                "brand:wikidata": "Q1963643",
                "brand:wikipedia": "de:Nah & Frisch",
                "key": "shop",
                "name": "Nah & Frisch",
                "operator": "Erich Hufnagl",
                "phone": "+43 2719 2362",
                "shop": "supermarket",
                "value": "supermarket",
                "website": "https://www.nahundfrisch.at/de/kaufmann/hufnagl_erich"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.586207,
                    48.474894
                ],
                "type": "Point"
            },
            "id": 11329560728,
            "properties": {
                "addr:city": "Lengenfeld",
                "addr:country": "AT",
                "addr:housenumber": "63",
                "addr:postcode": "3552",
                "addr:street": "Gföhler Straße",
                "amenity": "restaurant",
                "contact:phone": "+43 676 922 42 05",
                "cuisine": "heuriger",
                "key": "cuisine",
                "name": "Heuriger Loimer",
                "operator": "Weingut Franz Loimer",
                "outdoor_seating": "yes",
                "value": "heuriger",
                "website": "http://www.f-loimer.at/"
            },
            "type": "Feature"
        },
        {
            "geometry": {
                "coordinates": [
                    15.616842,
                    48.473813
                ],
                "type": "Point"
            },
            "id": 11397919664,
            "properties": {
                "addr:city": "Lengenfeld",
                "addr:country": "AT",
                "addr:housenumber": "1",
                "addr:postcode": "3552",
                "addr:street": "Am Golfplatz",
                "amenity": "restaurant",
                "cuisine": "regional",
                "key": "cuisine",
                "name": "GASThouse",
                "operator": "Golfclub Lengenfeld",
                "outdoor_seating": "yes",
                "phone": "+43 2719 871 03",
                "value": "regional",
                "website": "http://golflengenfeld.at/restaurant/"
            },
            "type": "Feature"
        }
    ],
    "type": "FeatureCollection"
}